<template>
	<div class="search-box">
		<div class="search-top" @click="goProAllList">
			<img class="left" src="@/assets/img-v3/new-home/searchIcon.png">
			<span>{{$t('home.search')}}</span>
			<van-icon @click.stop="scanCode" name="scan" class="right" color="#1CDDA0" size="20" />
		</div>
		<div class="search-type">
			<div class="type-item" @click="goRandomVideoPlay">
				<img src="@/assets/img/home/randomP.gif">
				<span>{{$t('home.random')}}</span>
			</div>
			<div class="type-item" @click="goProList(0)">
				<img src="@/assets/img-v3/new-home/222.png">
				<span>{{$t('home.topOne')}}</span>
			</div>
			<div class="type-item" @click="goSharingSpace(0)">
				<img src="@/assets/img-v3/new-home/333.png">
				<span>{{$t('home.everyday')}}</span>
			</div>

			<div class="type-item" @click="goSharingSpace(1)">
				<img src="@/assets/img-v3/new-home/555.png">
				<span>{{$t('home.fireworks')}}</span>
			</div>
			<div class="type-item" @click="goSharingSpace(2)">
				<img src="@/assets/img-v3/new-home/444.png">
				<span>{{$t('home.graphic')}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant'
	export default {
		name: 'SearchBox',
		methods: {
			scanCode() {
				if(!this.$store.state.wxData.appId){
					return
				}
				wx.scanQRCode({
					needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
					scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
					success: function(res) {
						var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
					}
				});
			},
			goProAllList() {
				this.$store.commit('SET_PRO_ACTIVE', 0)
				this.$router.push({
					name: 'ProAllList',
					query: {
						shopUuid: this.$store.state.shopUuid
					}
				})
			},
			goSharingSpace(type) {
				this.$router.push({
					name: 'SharingSpace',
					query: {
						type,
						shopUuid: this.$store.state.shopUuid
					}
				})
			},
			goProList(type) {
				this.$router.push({
					name: 'ProList',
					query: {
						type,
						shopUuid: this.$store.state.shopUuid
					}
				})
			},
			goRandomVideoPlay() {
				this.$router.push({
					name: 'RandomVideoPlay',
					query: {
						shopUuid: this.$store.state.shopUuid
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.search-box {
		width: 100%;
		height: 189px;
		background: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 71px;

		.search-top {
			width: 300px;
			height: 33px;
			background: #F4F4F4;
			border-radius: 15px;
			display: flex;

			align-items: center;
			position: relative;

			/*text-align: 10px;*/
			.left {
				margin: 0 8px 0 13px;
			}

			.right {
				position: absolute;
				right: 12px;
			}

			&>span {
				width: 210px;
				height: 33px;
				// line-height: 33px;
				text-align: center;
				display: flex;
				align-items: center;
				white-space: nowrap;
				font-size: 13px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #787878;
			}

			&>img {
				width: 16.5px;
				height: 16.5px;
			}
		}

		.search-type {
			width: 88%;
			height: 80px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 22px;

			.type-item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				// margin-right: 10px;
				img {
					width: 54.5px;
					height: 54.5px;
				}

				span {
					margin-top: 8px;
					font-size: 13px;
					font-family: HarmonyOS Sans SC;
					font-weight: 500;
				}
			}
		}
	}
</style>
