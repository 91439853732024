<template>
	<div class="home-container">
		<div class="home-container-header"  :style="{ '--background': pseudoElementBg}">
			<div class="text-title">
				<!-- <img class="home-logo" :src="logoWhite" alt=""> -->
				<img class="home-logo" :src="logoWhite" alt="">
			</div>
		</div>
		<div class="swipe">
			<div class="imgs">
				<!-- 轮播图 -->
				<Swipe type-name="deShopCarousel" @onChange="onChange"/>
			</div>
		</div>
		<!-- 搜索 -->
		<SearchBox />
		<!-- 图片展示 -->
		<ImageList />
		<!-- 推荐产品 -->
		<RecommendList :pro-list="proList"  @getInfos="getInfo" />
		<!-- 水印 -->
		<WaterMark />
	</div>
</template>

<script>
import { getShopOne, getProList,getAllBgColor ,getFileList} from '@/api';
import TopNavBar from './components/TopNavBar.vue';
import Swipe from '@/components/common/Swipe.vue';
import SearchBox from './components/SearchBox.vue';
import ImageList from './components/ImageList.vue';
import RecommendList from './components/RecommendList.vue';
// import { setBgColor } from '@/utils';
export default {
	name: 'Home',
	components: {
		TopNavBar,
		Swipe,
		SearchBox,
		ImageList,
		RecommendList
	},
	data() {
		return {
			proList: [],
			pseudoElementBg: '',
			logoWhite:''
		};
	},
	computed:{
		bgList(){
			return this.$store.state.bgColor
		},
		headUrl(){
			return this.$store.state.headUrl
		}
	},
	async mounted() {
		await this.getAllBgColor()
	},
	activated() {
		this.getProList();
		// if(this.$store.state.shopUuid){
			// getShopOne({uuid:this.$store.state.shopUuid}).then(res =>{
			// 	document.title = res.items.list.shopName
			// })
		// }
	},
	methods: {
		getInfo(data) {
			this.$router.push({
				name: 'ProductDetails',
				query: data
			});
		},
		 async getAllBgColor(shopUuid) {
			await getAllBgColor({ uuid: shopUuid || this.$store.state.shopUuid }).then(res => {
				if (res.state == 100) {
					this.$store.commit('SET_BG_COLOR', res.items);
					this.pseudoElementBg =  this.setBgColor('carouselColor11','carouselColor12');
				}
			});
			const params = {
				page: 0,
				pageSize: 0,
				typeName: 'deLogoWhite',
				parentUuid: this.$store.state.shopUuid
			};
			
			await getFileList(params).then(res => {
				if (res.state == 100) {
					this.logoWhite = res.items[0]?.filePath;
				}
			});
		},
		onChange(index){
			let i = index + 1;
			this.pseudoElementBg =  this.setBgColor('carouselColor'+i+'1','carouselColor'+i+'2');
		},
		setBgColor(key1,key2){
			let bgColor = this.bgList;
			let carouselColor1 = '#ffffff';
			let carouselColor2 = '#ffffff';
			for (let i = 0; i < bgColor.length; i++) {
				if (bgColor[i].item_key == key1) {
					carouselColor1 = bgColor[i].item_value
				}
				if (bgColor[i].item_key == key2) {
					carouselColor2 = bgColor[i].item_value
				}
			}
			return  'linear-gradient(90deg,' + carouselColor1 + ',' + carouselColor2 + ')'
		},
		getProList() {
			const params = {
				isOn: 0,
				page: 1,
				pageSize: 10,
				shopUuid: this.$store.state.shopUuid
			};
			getProList(params).then(res => {
				if (res.state == 100) {
					this.proList = res.items?.length > 10 ? res.items.slice(0, 10) : res.items;
				}
			});
		},
	}
};
</script>

<style lang="less" scoped>
.home-container {
	padding-bottom: 100px;
	background-color: #FFFFFF;
	.home-container-header{
		width: 100%;
		height: 243px;
		position: relative;
		z-index: 0;
		overflow: hidden;
		background: var(--background);
		.text-title {
			position: absolute;
			top: 20px;
			width: 100%;
			text-align: center;
			color: #ffffff;
			font-size: 15px;
			font-weight: 400;
			z-index: 999;
			.home-logo {
			  height: 33px;
			}
		}
		
	}
	.home-container-header::after{
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		content: '';
		height: 243px;
		background-image: url(../../assets/img/home/home-bg-img.png);
		background-size: 100% 100%;
	}
	
	&::before {
		content: '';
		display: table;
	}
	.swipe{
		 position: absolute;
		 width: 100%;
		 top: 55px;
		 display: flex;
		 justify-content: center;
		 z-index: 999;
		 .imgs{
			 width: 100%;
			 height: 250px;
			 // height: 15.625rem;
		 }
	}

	.top-nav-bar {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 999;
	}
}
</style>
