<template>
	<div class="top-nav">
		<img class="home-left" src="@/assets/img/home/homLeft.png" alt="" @click="goAllProList" />
		<img class="home-logo" :src="$store.state.headUrl" alt="" />
		<img class="home-right" src="@/assets/img/home/homeUser.png" alt="" @click="goProfile" />
	</div>
</template>

<script>
export default {
	name: 'TopNavBar',
	methods: {
		goAllProList() {
			this.$router.push({
				name: 'AllProList',
				query: {
					shopUuid: this.$store.state.shopUuid
				}
			});
		},
		goProfile() {
			this.$router.push({
				name: 'Profile',
				query: {
					shopUuid: this.$store.state.shopUuid
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.top-nav {
	height: 70px;
	padding: 15px;
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.home-logo {
	height: 33px;
	// width: 112px;
}
.home-left {
	width: 29px;
	height: 29px;
}
.home-right {
	width: 29px;
	height: 29px;
}
</style>
