<template>
  <div class="recommend-container">
    <div class="title">
	  <div style="width: 3px;height: 18px;background-color: #1989FA;border-radius: 50px;">  </div>
      <span style="padding-left: 5px;font-size:15px;color: #323232;font-weight: 400;">{{$t('home.recommend')}}</span><span style="color: #969696;font-size: 12px;font-weight: 400;padding-left: 5px;"> /  TUIJIANCHANPIN</span>
    </div>
    <div class="product-list">
      <ProductCard  v-for="(item,index) in proList" @getInfo="getInfo" :key="index" :info="item" />
    </div>
  </div>
</template>

<script>

import ProductList from '@c/common/ProductList.vue'
import ProductCard from '@c/common/ProductCard.vue'
export default {
  name: 'RecommendList',
  components: {
    ProductList,
	ProductCard
  },
  props: {
    proList: {
      type: Array,
      default: () => []
    }
  },
  methods:{
	  getInfo(data) {
	    this.$emit('getInfos',data)
	  },
  }

}
</script>

<style lang="less" scoped>
.recommend-container {
  margin-top: 8px;
  .product-list{
	  display: flex;
	  justify-content: space-between;
	  flex-wrap: wrap;
	  margin: 8px;
  }
  .title {
    background: #ffffff;
    width: 100%;
    line-height: 47px;
	// height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 7px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #323232;
	// margin: 0 8px 0 28px;
	padding-left: 21px;
    & > img {
      width: 20px;
      height: 20px;
      margin: 0 8px 0 28px;
    }
    span{
      // height: 47px;
      // line-height: 48px;
    }
  }
  
}
</style>
